export function ADD_INFO(data){
    return({type:'ADD_INFO',data});
}

export function ADD_SLIDER(data){
    return({type:'ADD_SLIDER',data});
}

export function ADD_CATEGORY(data){
    return({type:'ADD_CATEGORY',data});
}

export function ADD_PACKAGE(data){
    return({type:'ADD_PACKAGE',data});
}